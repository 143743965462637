<template>
  <div class="volunteerList">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParam.activityName" />
        <v-input
          label="手机号"
          :maxlength="11"
          v-model="searchParam.activityName"
        />
      </template>
      <template #operateSlot="scope">
        <!-- <v-button text="拉黑" type="text" @click="cancel(scope.row)" /> -->
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getVolunteerListUrl,
  blacklistVolunteerUrl,
  deleteVolunteerUrl,
} from "./api.js";

export default {
  name: "applyActivity",
  data() {
    return {
      scheduleList: [],
      auditDialogFormVisible: false,
      isdialog: false,
      searchParam: {
        phone: null,
        name: null,
      },
      communitList: [],
      tableUrl: getVolunteerListUrl,
      headers: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "pursueReason",
          label: "申请理由",
        },
      ],
    };
  },
  methods: {
    // 拉黑
    cancel(item) {
      this.$confirm("确定要拉黑该志愿者吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${blacklistVolunteerUrl}?id=${item.id}`)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            }
          });
      });
    },
    // 删除
    remove(item) {
      this.$confirm("确定要删除该志愿者吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.post(`${deleteVolunteerUrl}?id=${item.id}`).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.volunteerList {
  box-sizing: border-box;
  height: 100%;
}
</style>
